import React, { useEffect, useState, useRef } from "react";
// import videobg from "../Assets/0001-0130.mkv";
import "../Page/Style/Ladingpage.css";
import Header from "../Page/Component/Navebar";
import mediawork from "../Assets/mediwork.png";
import netcom from "../Assets/NETCOM (1).png";
import Fade from "react-reveal/Fade";
// import { hover } from "@testing-library/user-event/dist/hover";
import { Zoom } from "react-reveal";
import gillgallgray from "../Assets/gillgall_grey.png";
import gracecodegray from "../Assets/cc_grey.png";
import netcomgray from "../Assets/Netcom_grey.png";
import gillgallcolor from "../Assets/gillgall_color.png";
import netcomcolor from "../Assets/Netcom_color.png";
import gracecodecolor from "../Assets/cc_color.png";
import coverimg from "../Assets/cover.png";
import briefing from "../Assets/05.png";
import scriptwriting from "../Assets/04.png";
import stroryboarding from "../Assets/03.png";
import voiceover from "../Assets/02.png";
import finalanimation from "../Assets/01.png";
import Footer from "../Page/Component/Footer";
import Mod from "./Model";
import kevingray from "../Assets/kevinsBlack.png";
import kevincolor from "../Assets/kevinsColor.png";
export default function Landingpage() {
  // const [modalShow, setModalShow] = React.useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const pageTopRef = useRef(null);
  const servicePageTop = useRef(null);
  const scrollTeckStack = useRef(null);
  const scrollPartners= useRef(null)
  const scrollToTop = () => {
    console.log("came");
    pageTopRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const servicePart = () => {
  window.scrollTo({
      behavior: "smooth",
      top: servicePageTop.current.offsetTop -100,
    });
  };
  const scrollToTopTechStack=()=>{
    window.scrollTo({
      behavior: "smooth",
      top: scrollTeckStack.current.offsetTop -100,
    });
  }
  const scrollToPartners=()=>{
    window.scrollTo({
      behavior: "smooth",
      top: scrollPartners.current.offsetTop -100,
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cardsdetail = [
    {
      title: "Briefing",
      content: "We discuss about the visual concepts contents",
      image: briefing,
    },
    {
      title: "Script Writting",
      content: "We write the script for our video",
      image: scriptwriting,
    },
    {
      title: "Story Boarding",
      content: "We sketch the entire scenes and sequence",
      image: stroryboarding,
    },
    {
      title: "Voice-Over",
      content: "Voice over is recorded for videos",
      image: voiceover,
    },
    {
      title: "Final Animation out",
      content: "We submit the entire animation work with voice and bg musics.",
      image: finalanimation,
    },
  ];

  return (
    <>
      {/* <div className="backgroundblack"> */}
      <div
        class="main d-flex justify-content-center align-items-center flex-column"
        ref={pageTopRef}
      >
        <Header class="fixed-top" />
        <div class=" aboutdiv d-flex flex-column align-items-center text-align-center justify-content-center">
          <div class="netcommedialetter">
            <Fade left>
              <img src={netcom} alt="Netcom" id="firstimage" />
            </Fade>
            <Fade right>
              <img src={mediawork} alt="Mediawork" id="secondimage" />
            </Fade>
          </div>

          <div class="hidediv ">
            <h1 class="text-center text-white  2.5rem">
              {" "}
              <Zoom>About</Zoom>{" "}
            </h1>
            <p class="contentpara  2.5rem">
              <Zoom>
                We are a group of young and energetic entrepreneurial IT
                professionals wishing to provide a combination of technology,
                innovation, imagination &amp; Entrepreneurship. We are connect
                to this field since the year 2006 to create a landmark in Video
                creation using 2D and 3D animation technology. We are doing
                animation for short films, episodic works for television and for
                websites too. We have a team of disciplined animators who has a
                firm knowledge in animation techniques, fundamentals, and
                software. We have a well set team of animators to read through
                scripts and understand project needs, create storyboards and
                background, animations and assist in the editing process.When
                you prefer Netcom, you prefer perfection and satisfaction. You
                can experience our complete commitment towards your work.
              </Zoom>
            </p>
          </div>
        </div>
      </div>

      {/* <-- we have created Animation videos fro --> */}
      <div class="createdanimationvideos d-flex justify-content-center align-items-center flex-column mt-5" ref={scrollPartners}>
        <div class="d-flex justify-content-center align-items-center text-white ">
          <h1 className="animationvideos">
            We've created Animation videos for:
          </h1>
        </div>
        <div class="borderdiv d-flex justify-content-center  align-items-center  mt-3 border border-secondary rounded-4 border-5">
          <img src={gillgallgray} alt="Gillgallgrey" class="gillgallcolor cursor-pointer" />
          <img
            src={gillgallcolor}
            alt="Gillgallcolor"
            onClick={() => window.open("https://www.gillgall.com/")}
            class="gillgallcolors"
          />
          <div class=" verticalline vr m-4"></div>
          <hr class=" horizontalline hr" />
          <div class=" col-xs-2 v-divider"></div>
          <img src={netcomgray} alt="Netcomgray" class="netcomcolor" />
          <img
            src={netcomcolor}
            alt="Netcomcolor"
            onClick={() => window.open("https://www.ncpli.com/")}
            class="netcomcolors"
          />
          <div class="verticalline vr m-4"></div>
          <hr class=" horizontalline hr" />
          <img src={gracecodegray} alt="Gracecodegray" class="crazecodecolor" />
          <img
            src={gracecodecolor}
            alt="Gracecodecolor"
            onClick={() => window.open(" https://crazecode.com/")}
            class="crazecodecolors"
          />
          <div class="verticalline vr m-4"></div>
          <hr class=" horizontalline hr" />
          <img src={kevingray} alt="Kevingray" class="kevincolor" />
          <img
            src={kevincolor}
            alt="Kevincolor"
            onClick={() => window.open("https://www.kevinsproductions.com/")}
            class="kevincolors"
          />
        </div>
      </div>
      {/* <-- All onclick colors change  --> */}
      <div  ref={scrollTeckStack}>
        <img src={coverimg} alt="Coverimg" class=" w-100 mt-5" />
        
      </div>

      {/* Third BLock */}
      <div
        class="mt-5 d-flex flex-column justify-content-center align-items-center"
        ref={servicePageTop}
      >
        <h1 class="Animatingcontentprocessing d-flex justify-content-center">
          ANIMATING CONTENT PROCESSING
        </h1>

        <div class="d-flex justify-content-center gap-4 flex-wrap mt-5">
          {cardsdetail.map((carddata) => (
            <div className="titlecards">
              <div className="mappingimages">
                <img
                  class="card-img-top"
                  src={carddata.image}
                  alt="Card"
                />
            
              </div>
              <div className="cardbodys">
                <p className="cardtexts"> {carddata.title}</p>
                <p className="cardtext"> {carddata.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Fourth BLock */}
      <div class="container">
        <div class="applynow">
          <h3>BE PART OF OUR CREATIVE COMMUNITY</h3>
          <p>
            Apply for your job to join with our creative community ! <br></br>
            Please choose the role carefully and attach your latest updated
            Resume
          </p>
        </div>
        <div class="d-flex justify-content-end mb-2">
          <button
            //  onClick={() => setModalShow(true)}>
            onClick={handleShow}
          >
            <div class="text">
              <span>Apply</span>
              <span>Now</span>
            </div>
            <div class="clone">
              <span>Apply</span>
              <span>Now</span>
            </div>
            <svg
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 2 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              ></path>
            </svg>
          </button>

          <Mod sh={show} hide={handleClose} />
        </div>
      </div>
      <div>
        <Footer scrollToTop={scrollToTop} service={servicePart} scrollToTopTechStack={scrollToTopTechStack} scrollToPartners={scrollToPartners}/>
      </div>
      {/* </div> */}
    </>
  );
}
