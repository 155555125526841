import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import "../Page/Model.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
function Mod(props) {
  const [animatorthreed, setanimatorthreed] = React.useState("");
  const [threedhover, setthreedhover] = React.useState();
  const [texturingartisthover, settexturingartisthover] = React.useState();
  const [riggingartisthover, setriggingartisthover] = React.useState();
  const [renderingartisthover, setrenderingartisthover] = React.useState();
  const [modelingartisthover, setmodelingartisthover] = React.useState();
  const [vfxartisthover, setvfxartisthover] = React.useState();
  const [compositoreditorhover, setcompositoreditorhover] = React.useState();
  console.log(animatorthreed);
  // const threedanimator = (e) => {
  //   setanimatorthreed(e);
  // };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        show={props.sh}
        centered
        onHide={props.hide}
        // onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter ">
            <p>Latest Job Openings</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Roles :</h4>
          <div class="jobsbox">
            <div class="mapingdiv">
              {/* <--- 3D Animator  Button---> */}
              <div>
                <button
                  class="mappbut"
                  onClick={() => setanimatorthreed("3d")}
                  onMouseEnter={() => setthreedhover(true)}
                  onMouseLeave={() => setthreedhover(false)}
                >
                  <p
                    style={{
                      color: animatorthreed === "3d" ? "#07aa61" : "#111",
                    }}
                  >
                    3D Animator
                  </p>
                  <svg
                    style={{
                      color:
                        threedhover || animatorthreed === "3d"
                          ? "#07aa61"
                          : "#111",
                    }}
                    stroke-width="4"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="h-6 w-6 "
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <--- 3D Animator Button over---> */}

              {/* <--- 3D Texturing Artist Button---> */}
              <div>
                <button
                  class="TexturingArtist"
                  onClick={() => setanimatorthreed("TexturingArtist")}
                  onMouseEnter={() => settexturingartisthover(true)}
                  onMouseLeave={() => settexturingartisthover(false)}
                >
                  <p
                    style={{
                      color:
                        animatorthreed === "TexturingArtist"
                          ? "#07aa61"
                          : "#111",
                    }}
                  >
                    3D Texturing Artist
                  </p>
                  <svg
                    style={{
                      color:
                        texturingartisthover ||
                        animatorthreed === "TexturingArtist"
                          ? "#07aa61"
                          : "#111",
                    }}
                    stroke-width="4"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <--- 3D Rigging Artist---> */}

              <div>
                <button
                  class="RiggingArtist"
                  onClick={() => setanimatorthreed("RiggingArtist")}
                  onMouseEnter={() => setriggingartisthover(true)}
                  onMouseLeave={() => setriggingartisthover(false)}
                >
                  <p
                    style={{
                      color:
                        animatorthreed === "RiggingArtist" ? "#07aa61" : "#111",
                    }}
                  >
                    3D Rigging Artist
                  </p>
                  <svg
                    style={{
                      color:
                        riggingartisthover || animatorthreed === "RiggingArtist"
                          ? "#07aa61"
                          : "#111",
                    }}
                    stroke-width="4"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <---  Rendering Artist---> */}
              <div>
                <button
                  class="RenderingArtist"
                  onClick={() => setanimatorthreed("RenderingArtist")}
                  onMouseEnter={() => setrenderingartisthover(true)}
                  onMouseLeave={() => setrenderingartisthover(false)}
                >
                  <p
                    style={{
                      color:
                        animatorthreed === "RenderingArtist"
                          ? "#07aa61"
                          : "#111",
                    }}
                  >
                    {" "}
                    Rendering Artist
                  </p>
                  <svg
                    style={{
                      color:
                        renderingartisthover ||
                        animatorthreed === "RenderingArtist"
                          ? "#07aa61"
                          : "#111",
                    }}
                    stroke-width="4"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <--- 3D Modeling Artist---> */}
              <div>
                <button
                  class="ModelingArtist"
                  onClick={() => setanimatorthreed("ModelingArtist")}
                  onMouseEnter={() => setmodelingartisthover(true)}
                  onMouseLeave={() => setmodelingartisthover(false)}
                >
                  <p
                    style={{
                      color:
                        animatorthreed === "ModelingArtist"
                          ? "#07aa61"
                          : "#111",
                    }}
                  >
                    {" "}
                    Modeling Artist
                  </p>
                  <svg
                    style={{
                      color:
                        modelingartisthover ||
                        animatorthreed === "ModelingArtist"
                          ? "#07aa61"
                          : "#111",
                    }}
                    stroke-width="4"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <--- VFX Artist---> */}

              <div>
                <button
                  class="vfxArtist"
                  onClick={() => setanimatorthreed("vfxArtist")}
                  onMouseEnter={() => setvfxartisthover(true)}
                  onMouseLeave={() => setvfxartisthover(false)}
                >
                  <p
                    style={{
                      color:
                        animatorthreed === "vfxArtist" ? "#07aa61" : "#111",
                    }}
                  >
                    {" "}
                    VFX Artist
                  </p>
                  <svg
                    style={{
                      color:
                        vfxartisthover || animatorthreed === "vfxArtist"
                          ? "#07aa61"
                          : "#111",
                    }}
                    stroke-width="4"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>

              {/* <--- Compositor Editor---> */}
              <div>
                <button
                  class="CompositorEditor"
                  onClick={() => setanimatorthreed("CompositorEditor")}
                  onMouseEnter={() => setcompositoreditorhover(true)}
                  onMouseLeave={() => setcompositoreditorhover(false)}
                >
                  <p
                    style={{
                      color:
                        animatorthreed === "CompositorEditor"
                          ? "#07aa61"
                          : "#111",
                    }}
                  >
                    Compositor Editor
                  </p>
                  <svg
                    style={{
                      color:
                        compositoreditorhover ||
                        animatorthreed === "CompositorEditor"
                          ? "#07aa61"
                          : "#111",
                    }}
                    stroke-width="4"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="onevre  vr"></div>
            <div class="mapingdivs">
              <div>
                <span class="btn btn-primary btn-file">
                  Browse...
                  <input type="file" />
                </span>
              </div>
              <div>(or)</div>
              <div>
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Paste the URL"
                  >
                    <Form.Control type="email" placeholder="name@example.com" />
                  </FloatingLabel>
                </Col>
              </div>
              <div>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Write any message here (Optional)"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
              </div>
              {/* <-- Submit Button --> */}
              <div>
                <button class="btn">Submit</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Mod;
