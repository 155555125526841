import React, { useState } from "react";
import Netcomimg from "../../Assets/NMC_logo_dark.png";
import "../Component/fotter.css";
// import copy from "../../Assets/c-circle.svg";
import fb from "../../Assets/facebook (1).svg";
import insta from "../../Assets/instagram.svg";
import twitter from "../../Assets/twitter (1).svg";
import linkedin from "../../Assets/linkedin (1).svg";
// import Portfolio from "../Portfolio";
import { useNavigate } from "react-router-dom";
import Model from "../Model";
function Footer(props) {
  // const [open, setOpen] = useState();
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const navigate = useNavigate();
  const portfoliopage = () => {
    navigate("/Portfolio");
  };

  const gotoncpli = () => {
    window.open("https://www.ncpli.com/");
  };
  const gotogillgall = () => {
    window.open("https://www.gillgall.com/");
  };

  const gotogracecode = () => {
    window.open("https://www.crazecode.com/");
  };
  var date = new Date();
  return (
    <div class="backcolors d-flex justify-content-around mt-5">
      <div class="betweens">
        <div class="d-flex justify-content-center flex-column ">
          <img src={Netcomimg} alt="Netcomimg" class="footimg" />
          <div class="copyrightandletters d-flex">
            {/* <img src={copy} class="copysimpole"/> */}
            <p class="copyrightletter">
              © {date.getFullYear()} NetcomMediaWorks All Rights Reserved.
            </p>
          </div>
          <hr class="horiszontal hr" />
        </div>
        <div class="d-flex">
          <div class="firstvr vr"></div>
          <div class="sitemap">
            <h4 className="mapsite">Site Map</h4>
            <div class="tapresponsiveforsitemap ">
              <div class="h-25 d-flex flex-column justify-content-between mt-3">
                <h6 onClick={props.scrollToTop} className="cursorpoint">
                  ABOUT
                </h6>
                <h6 className="hiddenBlock">TEAM</h6>
                <h6 className="hiddenBlock">OUR TEAM</h6>
                <h6 onClick={portfoliopage} className="cursorpoint">
                  PORTFOLIO
                </h6>
              </div>
              <div class=" h-25 d-flex flex-column justify-content-between mt-3">
                <h6 onClick={props.service} className="cursorpoint">
                  SERVICES
                </h6>
                <h6 className="cursorpoint" onClick={props.scrollToPartners}>PARTNERS</h6>
                <h6 onClick={handleShow} className="cursorpoint">
                  CAREER
                </h6>
                <h6
                  onClick={props.scrollToTopTechStack}
                  className="cursorpoint"
                >
                  TECH STACK
                </h6>
              </div>
            </div>
          </div>
        </div>
        <hr class="horiszontalmiddle hr" />
        <div class="side d-flex mt-5">
          <div class="onevr  vr"></div>
          <div class="address">
            <div>
              <h4 class="headoffice">Head Office (TVL)</h4>
              <p class="lh-lg">
                No.1/1,Nathan Street, <br />
                Adaikalapuram,Murugankurichi, <br />
                Palayamkottai, <br />
                Tirunelveli-627002 <br />
                animation@ncpli.com <br />
                +91 9791509522, <br />
              </p>
            </div>
            <div>
              <h5 class="headoffice">Corporate Office (Chennai)</h5>
              <p className="lh-lg">
                No.5/3,Nathan Street, <br />
                Kush Kumar Road, Nungambakkam, <br />
                Chennai - 600 034. <br />
                044-42125369 <br />
              </p>
            </div>
          </div>
        </div>
        <hr class="horiszontalsecondmiddle hr" />
        <div class="sides">
          <div class="twovr vr"></div>
          <div class="socialmediaicon">
            <div class="test d-flex justify-content-between">
              <img
                src={fb}
                alt="Fb"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/people/Netcom-Computers-Pvt-Ltd/100083369047697/"
                  )
                }
                class="fontsize d-flex align-self-start mt-5"
              />
              <img
                src={insta}
                alt="Insta"
                onClick={() => window.open("https://www.instagram.com/netcom_computers_/")}
                class="fontsize d-flex align-self-start mt-5"
              />
              <img
                src={twitter}
                alt="Twitter"
                onClick={() => window.open("  https://twitter.com/NetcomTvl")}
                class="fontsize d-flex align-self-start mt-5"
              />
              <img
                src={linkedin}
                alt="Linkedin"
                onClick={() =>
                  window.open(
                    " https://www.linkedin.com/company/netcom-computers-pvt-ltd/mycompany/"
                  )
                }
                class="fontsize d-flex align-self-start mt-5"
              />
            </div>
            <hr class="hrea hr" />
            <p class="ourproducts h5">Our Other Products and Services</p>
            <p className="cursorpoint" onClick={gotogracecode}>
              www.crazecode.com
            </p>
            <p className="cursorpoint" onClick={gotogillgall}>
              www.gillgall.com
            </p>
            <p className="cursorpoint" onClick={()=>window.open("https://www.kevinsproductions.com/")} >www.kevinsproductions.com</p>
            <h5 class="headoffic">Parent Company</h5>
            <p className="cursorpoint" onClick={gotoncpli}>
              www.ncpli.com
            </p>
          </div>
        </div>
        <p class="copyrightletters">
          {" "}
          © {date.getFullYear()} NetcomMediaWorks All Rights Reserved.
        </p>
        <hr class="horiszontalbottom hr" />
      </div>
      <Model sh={show} hide={() => setShow(false)} />
    </div>
  );
}

export default Footer;
