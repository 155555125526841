import React, { useEffect } from "react";
import Navebar from "./Component/Navebar";
import "../Page/Portfolio.css";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Card from "react-bootstrap/Card";
// import Imageone from "../Assets/attak on feilds 3.jpg";
import Imagetwo from "../Assets/attak on feilds 5.jpg";
import Imagethre from "../Assets/war meeting.jpg";
import imagefour from "../Assets/camp attack 2.jpg";
import imagefive from "../Assets/drinking.jpg";
import imagesix from "../Assets/dry  cloth.jpg";
import imageseven from "../Assets/spying.jpg";
import ReactPlayer from "react-player";
import Footer from "./Component/Footer";
import Button from 'react-bootstrap/Button';

function Portfolio() {
   
  useEffect(() => {
      window.scrollTo(0,0)
  }, [])
  
 

  const portfolioimg = [
   
    {
 
      image: Imagetwo,
    },
    {
   
      image: Imagethre,
    },
    {

      image: imagefour,
    },
    {
  
      image: imagefive,
    },
    {
     
      image: imagesix,
    },
    {
    
      image: imageseven,
    },
  ];

  return (


    <div className="portfolionavbar">
      <Navebar class="fixed-top" />

      <div className="portfolicardvideos">
        <div className="portfolioletter">
          <div class="d-flex justify-content-center flex-row">
            <h1 class="text-dark fw-bolder">PORTFOLIO</h1>
          </div>
          <div class="d-flex flex-row justify-content-center flex-wrap align-items-center gap-4 w-75">
            {portfolioimg.map((portfoli) => (
              <div class="card">
                <img src={portfoli.image} alt="Portfoli" className="img" />
                <div class="textBox">
                  <p class="text head">{portfoli.content}</p>
            
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="colorbut">
        <Button onClick={()=>window.open ("https://www.youtube.com/@siruvarulagam348/videos")} >ViewMore</Button>
          </div>
        <div className="ourlatestlet">
         
        <div className="ourlatestletters">
        <h1>Our Latest Work</h1>
        </div>
        <div className="portfolioplayers"> 
          <ReactPlayer url="https://www.youtube.com/watch?v=csdTb5-gGOE"  class="portpoliovideos"/>
          <ReactPlayer url="https://www.youtube.com/watch?v=PXpL6VJa_AI" class="portpoliovideos"/>
          <ReactPlayer url="https://www.youtube.com/watch?v=v-OmGm1XxDA" class="portpoliovideos"/>
          <ReactPlayer url="https://www.youtube.com/watch?v=35RRnz1UCaQ" class="portpoliovideos"/>
        </div>
        </div>
      </div>
      <div >
      <Footer/>
      </div>
    </div>
  );
}

export default Portfolio;
