import React from "react";
import "../../Page/OurTeam/Ourteam.css";
import img from "../../Assets/basketball-155997_960_720.png";
import Navebar from "../Component/Navebar";
import shabanaimg from "../../Assets/ShabanaMali - 3D genaralist.png";
// import rijimonimg from "../../Assets/Rijomon Kuruvila - texturing Artist.png";
// import abhiragimg from "../../Assets/Abhirag - Concept artist.png";
// import manjimaimg from "../../Assets/Manjima santhosh - 2D animator .png";
// import vishnuvijaynimg from "../../Assets/Vishnu Vijayan - 3D genaralist .png";
// import sajiimg from "../../Assets/saji C S - 3D genaralist .png";
import Footer from "../Component/Footer";
function Ourteam() {
  return (
   
     <>
      <div className="ourteamholl">
    <div className="ourteammain">
      <div className="navabartest">
        <Navebar />
      </div>
      {/* <div class='d-flex justify-content-center'>
    <h1>MEET OUR TEAM</h1>
    </div> */}
      <div className="Meetourteamletters">
        <h1>MEET OUR TEAM</h1>
      </div>
      <div className="ourTeams">
        <div className="firstfourimg">
          <div className="imagone">
            <div class="c">
              <div class="side heads">
                <img
                  src={shabanaimg}
                  alt="Shabanaimg"
                  style={{ width: "190px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Ashok</p> <br />
                  <p>Designation: AnimatorHead</p>
                </p>
              </div>
            </div>
          </div>
          {/* <--- 2nd Image---> */}
          <div className="imgetwo">
            <div class="a">
              <div class="side heads">
                <img
                  src={img}
                  alt="Img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:kumaras</p> <br />
                  <p>Designation: Animator</p>
                </p>
              </div>
            </div>
          </div>
          {/* <--Sherlin Imasge--> */}
          <div className="imgethree">
            <div class="Sherlin">
              <div class="side heads">
                <img
                  src={img}
                  alt="Img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Sherlin</p> <br />
                  <p>Designation: Animator</p>
                </p>
              </div>
            </div>
          </div>
          {/* <--Nithin Imasge--> */}
          <div className="imgefour">
            <div class="Nithin">
              <div class="side heads">
                <img
                  src={img}
                  alt="Img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Nithin</p> <br />
                  <p>Designation: Animatorsssssss</p>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <--Ashik Imasge--> */}
        <div className="secondfourimg">
          <div className="imgefive">
            <div class="Ashik">
              <div class="side heads">
                <img
                  src={img}
                  alt="Img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Ashiks</p> <br />
                  <p>Designation: Animator</p>
                </p>
              </div>
            </div>
          </div>
          {/* <--Vishnu Imasge--> */}
          <div className="imagesix">
            <div class="Vishnu">
              <div class="side heads">
                <img
                  src={img}
                  alt="Img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Vishnus</p> <br />
                  <p>Designation: Animator</p>
                </p>
              </div>
            </div>
          </div>

          {/* <--Vishnuvijay Imasge--> */}
          <div className="imageseven">
            <div class="Vishnuvijay">
              <div class="side heads">
                <img
                  src={img}
                  alt="Img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Vishnuvijay</p> <br />
                  <p>Designation: Animator</p>
                </p>
              </div>
            </div>
          </div>
          {/* <--Manjuma Imasge--> */}
          <div className="imageeight">
            <div class="Manjuma">
              <div class="side heads">
                <img
                  src={img}
                  alt="Img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Vishli</p> <br />
                  <p>Designation: Animator</p>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <--- ThiredFourthImage --> */}
        {/* <--Neethu Imasge--> */}
        <div className="thiredfourimg">
          <div className="imagenine">
            <div class="Neethu">
              <div class="side heads">
                <img
                  src={img}
                  alt="Img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Ithrish</p> <br />
                  <p>Designation: Animator</p>
                </p>
              </div>
            </div>
          </div>
          {/* <--Akhil Imasge--> */}
          <div className="imageten">
            <div class="Akhil">
              <div class="side heads">
                <img
                  src={img}
                  alt="img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Jeni</p> <br />
                  <p>Designation: Animator</p>
                </p>
              </div>
            </div>
          </div>
          {/* <--Saji Imasge--> */}
          <div className="imageeleven">
            <div class="Saji">
              <div class="side heads">
                <img
                  src={img}
                  alt="Img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Marshal</p> <br />
                  <p>Designation: Animator</p>
                </p>
              </div>
            </div>
          </div>
          {/* <--Abhirag Imasge --> */}
          <div className="imagetwel">
            <div class="Abhirag">
              <div class="side heads">
                <img
                  src={img}
                  alt="img"
                  style={{ width: "150px", marginBottom: "50px" }}
                />
              </div>
              <div class="side tails">
                <p style={{ fontSize: "20px", marginTop: "50px" }}>
                  <p> Name:Grace</p> <br />
                  <p>Designation: Animator</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
    
    <Footer/>
    
    </div>
    </>
  );
}

export default Ourteam;
