
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import videobg from '../src/Assets/0001-0130.mkv'
import { BrowserRouter,  Routes, Route} from "react-router-dom";
import Landingpage from '../src/Page/Landingpage';
import Navbarcom from '../src/Page/Component/Navebar';
// import Footer from './Page/Component/Footer';
// import Modal from './Page/Model';
import Portfolio from './Page/Portfolio';
import Ourteam from './Page/OurTeam/Ourteam';
// import Navebar from '../src/Page/Component/Navebar';
function App() {
  return (
   <BrowserRouter>
     <Routes>
       <Route path="/" element={<Landingpage/>}/> 
       <Route path="/"element={<Navbarcom/>}/> 
       <Route path="/Portfolio"element={<Portfolio/>}/> 
       <Route path="/Ourteam"element={<Ourteam/>}/> 
       {/* <Route path="/"element={<Footer/>}/> */}
       {/* <Route path="/"element={<Footer/>}/> */}
       {/* <Route path="/"element={<Navebar/>}/>  */}
     
     </Routes>
   </BrowserRouter>
  );
}

export default App;
