// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
import "../Component/nav.css";
import medialogo from "../../Assets/medialogo.png";
// import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
// import Form from "react-bootstrap/Form";
// import Portfolio from "../Portfolio";
// import Ourteam from "../OurTeam/Ourteam";
import React, { useState } from "react";
import Model from "../Model";
// import Landingpage from "../Landingpage";
import {
  // Divider,
  Drawer,
  IconButton,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
// import MenuIcon from '@mui/icons-material/Menu';
function Navebar() {
  const navigate = useNavigate();
  const portfoliopage = () => {
    navigate("/Portfolio");
  };
  const landinghomepage = () => {
    navigate("/");
  };
  const [open, setopen] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setopen(false);
  };
  const handleDrawerOpen = () => {
    setopen(true);
  };
  const drawercolse = () => {
    setopen(false);
  };
  return (
    <div class="maindiv">
      <nav class="navbar navbar-expand-lg fixed-top  navbar-light">
        <div>
          <Link to="/" className="navbar-brand">
            <img src={medialogo} alt="Medialogo" className="logocolor" />
          </Link>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#myNavBar"
          onClick={handleDrawerOpen}
        >
          <span class="navbar-toggler-icon"> </span>
        </button>

        <Drawer anchor="right" open={open}>
          <div>
            <IconButton onClick={drawercolse}>
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </div>

          <div className="drawerdiv">
            <div className="draweroveralldiv">
              <Link to="/" className="nav-link" onClick={landinghomepage}>
                Home
              </Link>

              <Link
                to="/Portfolio"
                className="nav-link"
                onClick={portfoliopage}
              >
                Portfolio
              </Link>

              <li className="nav-item d-flex justify-content-center fs-3">
                <span className="nav-link mt-3" onClick={handleShow}>
                  {"Jobs >"}
                </span>
              </li>
            </div>
          </div>
        </Drawer>

        <div class="collapse navbar-collapse fs-4 bg " id="myNavBar">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 d-flex justify-content-evenly align-items-center">
            <li class="nav-item">
              <a
                href="#Home"
                class="hover-underline-animation"
                onClick={landinghomepage}
              >
                Home
              </a>
            </li>

            <li class="nav-item">
              <a
                href="#Contact"
                class="hover-underline-animation"
                onClick={portfoliopage}
              >
                Portfolio
              </a>
            </li>
            <li className="nav-item d-flex justify-content-center fs-3">
              <Link to="#Jobs" className="nav-link mt-3" onClick={handleShow}>
                    <span className="btns">{' Jobs >'}</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <Model sh={show} hide={handleClose} />
      {/* <!--Header End--> */}
    </div>
  );
}

export default Navebar;
